import { Box, Container, Paper, Typography } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import React from 'react';

import { Layout, SEO } from '../components';

export default function Error404(props) {
  return (
    <Layout {...props}>
      <SEO title="404" pathname={props.location.pathname} />
      <Container maxWidth="md">
        <Box component={Paper} m={4} p={2}>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              marginBottom: '14px',
            }}
          >
            Error 404: Page not found
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: '14px',
            }}
          >
            Looks like the page you are trying to visit does not exist.
          </Typography>
          <Button to="/" variant="contained" color="primary">
            Go Home
          </Button>
        </Box>
      </Container>
    </Layout>
  );
}
